<template>
  <div class="flatfinder-filter-type-range">
    <div class="flatfinder-filter-type-range__header">
      <label>
        <span>{{ label }}</span>
        <FontAwesomeIcon :icon="$attrs.icon" class="ml-1" />
      </label>
      <slot name="remove"></slot>
    </div>
    <div class="flatfinder-filter-type-range__value">
      <InputComponent
        :value="getValue(0)"
        align="right"
        :suffix="suffix"
        @input="$ev => setValue($ev, 0)"
        :mask="mask"
      />
      <span>-</span>
      <InputComponent
        :value="getValue(1)"
        align="right"
        :suffix="suffix"
        @input="$ev => setValue($ev, 1)"
        :mask="mask"
      />
    </div>
  </div>
</template>

<script>
import { InputComponent } from 'vue-elder-input'

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    min: Number,
    max: Number,
    suffix: String,
    mask: {
      type: Object,
      default: () => ({ mask: Number, unmask: true, thousandsSeparator: ' ' }),
    },
    interval: Number,
    label: String,
  },
  methods: {
    setValue(val, index) {
      if (index === undefined) return this.$emit('input', val)
      this.$emit('input', index === 0 ? [val, this.value[1]] : [this.value[0], val])
    },
    getValue(index) {
      if (index === undefined) return this.value
      return this.value[index]
    },
  },
  components: {
    InputComponent,
  },
}
</script>

<style lang="scss">
.flatfinder-filter-type-range {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    font-size: 0.8em;
    margin-bottom: 0.25rem;
  }

  &__value {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .elder-input__element,
  .elder-input__suffix {
    padding: 0.1em 0.25em;
    color: rgba(black, 0.6);
    background-color: rgba(white, 0.5);
  }

  .elder-input__suffix {
    font-size: 0.8em;
    display: flex;
    align-items: center;
  }

  .elder-input__field {
    background-color: rgba(white, 0.4);
  }
}
</style>
