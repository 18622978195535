<template>
  <div v-if="isValid" class="range-slider">
    <label :for="id" v-if="label" class="range-slider__label">
      {{ label }}
    </label>
    <input
      :class="[
        'range-slider__input range-slider__input--left',
        { 'range-slider__input--elevated': isOverlap && selected === 0 },
      ]"
      type="range"
      ref="leftRef"
      :step="step"
      :min="min"
      :max="max"
      :value="getValue(0)"
      @input="$ev => handleValueChange($ev, 0)"
    />
    <input
      :class="[
        'range-slider__input range-slider__input--right',
        { 'range-slider__input--elevated': isOverlap && selected === 1 },
      ]"
      type="range"
      ref="rightRef"
      :step="step"
      :min="min"
      :max="max"
      :value="getValue(1)"
      @input="$ev => handleValueChange($ev, 1)"
    />
    <div class="range-slider__slider">
      <div class="range-slider__slider-track"></div>
      <div ref="rangeRef" class="range-slider__slider-range"></div>
      <div class="range-slider__slider__values" v-if="numberComp">
        <div
          class="range-slider__slider__values__value range-slider__slider__values__value-left-value"
        >
          {{ numberComp[0]
          }}<span :for="id" v-if="hasSuffix" class="range-slider__slider__values--suffix">
            <slot name="suffix">{{ suffix }}</slot>
          </span>
        </div>
        <div
          class="range-slider__slider__values__value range-slider__slider__values__value-right-value"
        >
          {{ numberComp[1]
          }}<span :for="id" v-if="hasSuffix" class="range-slider__slider__values--suffix">
            <slot name="suffix">{{ suffix }}</slot>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    min: { type: Number, required: true },
    max: { type: Number, required: true },
    label: String,
    step: Number,
    suffix: String,
    type: String,
  },
  data() {
    return {
      id: null,
      selected: null,
    }
  },
  computed: {
    isValid() {
      return Number.isInteger(this.min) && Number.isInteger(this.max)
    },
    hasSuffix() {
      return this.suffix || this.$slots.suffix
    },
    numberComp() {
      let leftValue = this.value[0] || this.min
      let rightValue = this.value[1] || this.max
      if (this.type === 'currency') {
        const numberFormat = new Intl.NumberFormat('no-NB', {
          style: 'currency',
          currency: 'NOK',
          maximumFractionDigits: 0,
        })
        leftValue = numberFormat.format(leftValue)
        rightValue = numberFormat.format(rightValue)
      }
      return [leftValue, rightValue]
    },
    isOverlap() {
      return Math.abs(this.value[1] - this.value[0]) === 1
    },
  },
  methods: {
    updateRange(index) {
      const minPercent = this.getPercent(this.$refs.leftRef.value)
      const maxPercent = this.getPercent(this.$refs.rightRef.value)
      if (index === 0) {
        this.$refs.rangeRef.style.left = `${minPercent + 0.5}%`
        this.$refs.rangeRef.style.width = `${maxPercent - minPercent}%`
      } else {
        this.$refs.rangeRef.style.width = `${maxPercent - minPercent}%`
      }
    },
    getPercent(value) {
      return Math.round(((value - this.min) / (this.max - this.min)) * 100)
    },
    handleValueChange(event, index) {
      const val = +event.target.value
      if (index === undefined) return this.$emit('input', this.value)

      this.$emit(
        'input',
        index === 0
          ? [Math.min(+val, +this.$refs.rightRef.value - 1), +this.$refs.rightRef.value]
          : [+this.$refs.leftRef.value, Math.max(val, +this.$refs.leftRef.value + 1)],
      )
      this.updateRange(index)
      this.selected = index
    },
    getValue(index) {
      if (index === undefined) return this.value
      return this.value[index]
    },
  },
  created() {
    this.id = this._uid
  },
  mounted() {
    this.updateRange(0)
  },
}
</script>
<style lang="scss">
.range-slider {
  width: 100%;
  margin-top: 1rem;
  position: relative;

  &__label {
    font-weight: bold;
    display: flex;
    gap: 0.55em;
    margin-bottom: 1rem;
  }

  &__input,
  &__input::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  &__input {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;

    &--left {
      z-index: 3;
      left: -1px;
    }

    &--right {
      z-index: 4;
    }

    &--elevated {
      z-index: 5;
    }
  }

  @mixin slider {
    background-color: css-lighten('primary', 10%);
    border: none;
    border-radius: 50%;
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid css-darken('primary', 0%);
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 2px;
    pointer-events: all;
    position: relative;

    @media screen and (max-width: 768px) {
      height: 25px;
      width: 25px;
    }
  }

  &__input::-webkit-slider-thumb {
    @include slider;
  }

  &__input::-moz-range-thumb {
    @include slider;
  }

  &__slider {
    position: relative;
    width: 100%;

    &-track,
    &-range,
    &-left-value,
    &-right-value {
      position: absolute;
    }

    &-track,
    &-range {
      border-radius: 3px;
      height: 0.4rem;
    }

    &-track {
      background-color: #e3e3e3;
      width: 100%;
      z-index: 1;
    }

    &-range {
      background-color: css-lighten('primary', 35%);
      width: 100%;
      z-index: 2;
    }

    &__values {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__value {
        display: flex;
        align-items: center;
        gap: 0.2rem;

        &-left-value,
        &-right-value {
          color: var(--dark);
          font-size: 1rem;
          margin-top: 1.2rem;
        }

        &-left-value {
          left: 0.5rem;
        }

        &-right-value {
          right: -0.25rem;
        }

        &--suffix {
          display: block;
          flex-shrink: 0;
          font-size: 0.8em;
          padding: 0.1em 0.25em;
          color: rgba(0, 0, 0, 0.6);
          border-left: 1px solid var(--vue-elder-border-color);
        }
      }
    }
  }
}
</style>
